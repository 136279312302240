:root {
    font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
    line-height: 1.5;
    font-weight: 400;

    color-scheme: dark;
    color: white;
    background-color: var(--blue);

    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    --light-border: 1px solid var(--grey);

    --dark-grey: #1a1a1a;
    --grey: hsl(60, 4%, 68%);
    --green: hsl(114, 100%, 26%);
    --s-bahn-blue: #0097D9;
    --blue: hsl(228, 45%, 24%);
    --red: hsl(5, 84%, 40%);
    --orange: hsl(40, 100%, 49%);
}

*, *:before, *:after {
    box-sizing: border-box;
}

html, body {
    margin: 0;
    padding: 0;
}


html {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: fixed;
    overscroll-behavior: none;
}

body {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: fixed;
    overscroll-behavior: none;
    margin: 0;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    background: var(--blue);
}

main, footer {
    display: flex;
}

main {
    flex-grow: 1;
    overflow: hidden;
    padding: 1rem 1rem 0 1rem;
}

main > *, footer > * {
    flex-grow: 1;
    width: 100%;
}

* {
    font-family: FrutigerNextPro-Regular, sans-serif;
}

@font-face {
    font-family: "FrutigerNextPro-Regular";
    src: url("../../fonts/FrutigerNextPro-Regular.otf") format("opentype")
}

@font-face {
    font-family: "FrutigerNextPro-Bold";
    src: url("../../fonts/FrutigerNextPro-Bold.otf") format("opentype")
}

@font-face {
    font-family: "oebb-symbols";
    src: url("../../fonts/oebb.ttf") format("truetype");
}

@font-face {
    font-family: "oebb-light-symbols";
    src: url("../../fonts/oebb-light.ttf") format("truetype");
}

@font-face {
    font-family: "mav-symbols";
    src: url("../../fonts/mav.ttf") format("truetype");
}

@font-face {
    font-family: "db-symbols";
    src: url("../../fonts/db.ttf") format("truetype");
}